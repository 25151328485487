// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-quick-panel {
  display: flex;
  width: 350px;
  min-width: 350px;
  max-width: 350px;
  z-index: 99;
  flex-direction: column;
}
app-quick-panel .mat-slide-toggle-content {
  flex: 1;
}
app-quick-panel .span-spacer {
  flex: 1 1 auto;
}
app-quick-panel .time-alert {
  text-align: right;
  color: gray;
  margin-top: 10px;
}
app-quick-panel .cursor-pointer {
  float: right;
  cursor: pointer;
}
app-quick-panel .mat-list-text {
  padding-right: 16px !important;
}
app-quick-panel .show-alert {
  height: auto !important;
  margin-bottom: 25px;
  margin-top: 15px;
}
app-quick-panel .show-alert .error-message {
  white-space: inherit !important;
}`, "",{"version":3,"sources":["webpack://./src/app/main/quick-panel/quick-panel.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,YAAA;EACA,gBAAA;EACA,gBAAA;EACA,WAAA;EACA,sBAAA;AACF;AACE;EACE,OAAA;AACJ;AAEE;EACE,cAAA;AAAJ;AAGE;EACE,iBAAA;EACA,WAAA;EACA,gBAAA;AADJ;AAIE;EACE,YAAA;EACA,eAAA;AAFJ;AAKE;EACE,8BAAA;AAHJ;AAME;EACE,uBAAA;EACA,mBAAA;EACA,gBAAA;AAJJ;AAMI;EACE,+BAAA;AAJN","sourcesContent":["app-quick-panel {\n  display: flex;\n  width: 350px;\n  min-width: 350px;\n  max-width: 350px;\n  z-index: 99;\n  flex-direction: column;\n\n  .mat-slide-toggle-content {\n    flex: 1;\n  }\n\n  .span-spacer {\n    flex: 1 1 auto;\n  }\n\n  .time-alert {\n    text-align: right;\n    color: gray;\n    margin-top: 10px;\n  }\n\n  .cursor-pointer {\n    float: right;\n    cursor: pointer;\n  }\n\n  .mat-list-text {\n    padding-right: 16px !important;\n  }\n\n  .show-alert {\n    height: auto !important;\n    margin-bottom: 25px;\n    margin-top: 15px;\n\n    .error-message {\n      white-space: inherit !important;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
