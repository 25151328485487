import { Component, Input, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  public defaultEnvironment: string;
  @Input() environment: any = {
    environment: '',
    version: ''
  };

  constructor() { 
    this.defaultEnvironment = environment.title;
  }

  ngOnInit() {
    
  }

}
