// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .p-button {
  background: #003865;
  border-color: #003865;
}
:host ::ng-deep p-button {
  transition: none !important;
}
:host ::ng-deep .p-button:enabled:hover {
  background: #00233f;
  border-color: #001c32;
}
:host ::ng-deep .p-button-secondary {
  background: #6c757d;
  border-color: #6c757d;
}
:host ::ng-deep .p-button-secondary:enabled:hover {
  background: #5a6268;
  border-color: #545b62;
}
:host ::ng-deep .p-button-success {
  background: #3bb56e;
  border-color: #3bb56e;
}
:host ::ng-deep .p-button-success:enabled:hover {
  background: #32985c;
  border-color: #2e8f57;
}
:host ::ng-deep .p-button-info {
  background: #4ca8e6;
  border-color: #4ca8e6;
}
:host ::ng-deep .p-button-info:enabled:hover {
  background: #2a98e1;
  border-color: #1f92e0;
}
:host ::ng-deep .p-button-danger {
  background: #c84837;
  border-color: #c84837;
}
:host ::ng-deep .p-button-danger:enabled:hover {
  background: #aa3d2f;
  border-color: #a03a2c;
}
:host ::ng-deep .p-button-help {
  background: #702f8a;
  border-color: #702f8a;
}
:host ::ng-deep .p-button-help:enabled:hover {
  background: #702f8a;
  border-color: #702f8a;
}
:host ::ng-deep .p-button-gold {
  background: #ffc000;
  border-color: #ffc000;
}
:host ::ng-deep .p-button-gold:enabled:hover {
  background: #ffc000;
  border-color: #ffc000;
}`, "",{"version":3,"sources":["webpack://./src/app/core/controls/jabil-button/jabil-button.component.scss"],"names":[],"mappings":"AACC;EACC,mBAAA;EACA,qBAAA;AAAF;AAGC;EACC,2BAAA;AADF;AAKC;EACC,mBAAA;EACA,qBAAA;AAHF;AAKC;EACC,mBAAA;EACA,qBAAA;AAHF;AAKC;EACC,mBAAA;EACA,qBAAA;AAHF;AAKC;EACC,mBAAA;EACA,qBAAA;AAHF;AAMC;EACC,mBAAA;EACA,qBAAA;AAJF;AAMC;EACC,mBAAA;EACA,qBAAA;AAJF;AAMC;EACC,mBAAA;EACA,qBAAA;AAJF;AAMC;EACC,mBAAA;EACA,qBAAA;AAJF;AAMC;EACC,mBAAA;EACA,qBAAA;AAJF;AAMC;EACC,mBAAA;EACA,qBAAA;AAJF;AAMC;EACC,mBAAA;EACA,qBAAA;AAJF;AAMC;EACC,mBAAA;EACA,qBAAA;AAJF;AAMC;EACC,mBAAA;EACA,qBAAA;AAJF","sourcesContent":[":host ::ng-deep {\n\t.p-button {\n\t\tbackground: #003865;\n\t\tborder-color: #003865;\n\t}\n\n\tp-button {\n\t\ttransition: none !important;\n\n\t}\n\n\t.p-button:enabled:hover {\n\t\tbackground: #00233f;\n\t\tborder-color: #001c32;\n\t}\n\t.p-button-secondary {\n\t\tbackground: #6c757d;\n\t\tborder-color: #6c757d;\n\t}\n\t.p-button-secondary:enabled:hover {\n\t\tbackground: #5a6268;\n\t\tborder-color: #545b62;\n\t}\n\t.p-button-success {\n\t\tbackground: #3bb56e;\n\t\tborder-color: #3bb56e;\n\t}\n\n\t.p-button-success:enabled:hover {\n\t\tbackground: #32985c;\n\t\tborder-color: #2e8f57;\n\t}\n\t.p-button-info {\n\t\tbackground: #4ca8e6;\n\t\tborder-color: #4ca8e6;\n\t}\n\t.p-button-info:enabled:hover {\n\t\tbackground: #2a98e1;\n\t\tborder-color: #1f92e0;\n\t}\n\t.p-button-danger {\n\t\tbackground: #c84837;\n\t\tborder-color: #c84837;\n\t}\n\t.p-button-danger:enabled:hover {\n\t\tbackground: #aa3d2f;\n\t\tborder-color: #a03a2c;\n\t}\n\t.p-button-help {\n\t\tbackground: #702f8a;\n\t\tborder-color: #702f8a;\n\t}\n\t.p-button-help:enabled:hover {\n\t\tbackground: #702f8a;\n\t\tborder-color: #702f8a;\n\t}\n\t.p-button-gold {\n\t\tbackground: #ffc000;\n\t\tborder-color: #ffc000;\n\t}\n\t.p-button-gold:enabled:hover {\n\t\tbackground: #ffc000;\n\t\tborder-color: #ffc000;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
