import { Component, Input, forwardRef, OnInit, ViewChild, ElementRef, Injector } from '@angular/core';
import { FormControl, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'jabil-textarea',
  templateUrl: './jabil-text-area.component.html',
  styleUrls: ['./jabil-text-area.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => JabilTextAreaComponent),
      multi: true
    }
  ]
})
export class JabilTextAreaComponent implements OnInit {
  @Input() caption: string;
  @Input() nameControl: string;
  @Input() rows: number = 1;
  @Input() isReadOnly: boolean = false;
  value: any;
  isDisabled: boolean;
  onChange = (_: any) => { }
  onTouch = () => { }
  fm = new FormControl();
  ngControl: NgControl;
  @ViewChild("input") input: ElementRef;

  constructor(private injector: Injector) { }

  ngOnInit(): void {
    this.ngControl = this.injector.get(NgControl);
    this.fm.valueChanges.subscribe(v => {
      this.onChange(v);
    });
  }

  onInput(value: any) {
    this.value = value;
    this.onTouch();
    this.onChange(this.value);

    if (this.value) {
      let element = this.input.nativeElement as HTMLElement;
      element.classList.remove("customError");
    }
  }

  writeValue(value: any): void {
    if (value) {
      this.value = value || '';
    } else {
      this.value = '';
    }
  }

  registerOnChange(fn: any): void { this.onChange = fn; }
  registerOnTouched(fn: any): void { this.onTouch = fn; }
  setDisabledState(isDisabled: boolean): void { this.isDisabled = isDisabled; }

}
