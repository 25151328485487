// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customError {
  border-color: red;
}

textarea {
  resize: none;
}`, "",{"version":3,"sources":["webpack://./src/app/core/controls/jabil-text-area/jabil-text-area.component.scss"],"names":[],"mappings":"AAAA;EACC,iBAAA;AACD;;AACA;EACC,YAAA;AAED","sourcesContent":[".customError {\n\tborder-color: red;\n}\ntextarea {\n\tresize: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
