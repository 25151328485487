import { Injectable } from '@angular/core';
import { RestCallService } from '../../../../core/services/rest-call/rest-call.service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from 'src/app/core/services/notification/notification.service';
import { TranslateApplicationService } from '../../../../core/translate/translate-application-service';
import { ISite } from '../interfaces/ISite';
import { IBaseParams } from '../interfaces/IBaseParams';

@Injectable({
  providedIn: 'root'
})
export class SiteService extends RestCallService {

  constructor(
    public httpClient: HttpClient,
    public toastr: ToastrService,
    public notificationService: NotificationService,
    public translate: TranslateApplicationService
  ) {
    super(httpClient, 'JabilPTUApi', toastr, notificationService, translate);
  }
  
  public GetSitesByUsername()
  {
    return this.post<ISite[]>('Sites/GetSitesByUsername');
  }
}
