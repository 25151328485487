// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ng-select.customError ::ng-deep .ng-select-container {
  border-color: red;
}`, "",{"version":3,"sources":["webpack://./src/app/core/controls/jabil-select/jabil-select.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;AACF","sourcesContent":[".ng-select.customError ::ng-deep .ng-select-container {\n  border-color: red;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
