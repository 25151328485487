import { Injectable } from '@angular/core';
import { RestCallService } from '../../../../core/services/rest-call/rest-call.service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from 'src/app/core/services/notification/notification.service';
import { TranslateApplicationService } from '../../../../core/translate/translate-application-service';
import { IEnvironment } from '../interfaces/IEnvironments';
import { IS3FilesApiUrls } from '../interfaces/IS3FilesApiUrls';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService extends RestCallService {

  constructor(
    public httpClient: HttpClient,
    public toastr: ToastrService,
    public notificationService: NotificationService,
    public translate: TranslateApplicationService
  ) {
    super(httpClient, 'JabilPTUApi', toastr, notificationService, translate);
  }
  
  public GetActiveEnvironment()
  {
    return this.post<IEnvironment>('Environments/GetActiveEnvironment');
  }

  public GetS3FilesApiUrls()
  {
    return this.post<IS3FilesApiUrls>('Environments/GetS3FilesApiUrls');
  }
}
