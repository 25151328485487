// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.folded:not(.folded-open) :host .nav-link > span {
  opacity: 0;
  transition: opacity 200ms ease;
}
:host .nav-link .collapse-arrow {
  transition: transform 0.3s ease-in-out, opacity 0.25s ease-in-out 0.1s;
  transform: rotate(0);
}
:host > .children {
  overflow: hidden;
}
:host.open > .nav-link .collapse-arrow {
  transform: rotate(90deg);
}`, "",{"version":3,"sources":["webpack://./src/app/core/components/navigation/vertical/nav-collapse/nav-vertical-collapse.component.scss"],"names":[],"mappings":"AAKY;EACI,UAAA;EACA,8BAAA;AAJhB;AAUQ;EACI,sEAAA;EACA,oBAAA;AARZ;AAYI;EACI,gBAAA;AAVR;AAiBY;EACI,wBAAA;AAfhB","sourcesContent":[":host {\n\n    .folded:not(.folded-open) & {\n        .nav-link {\n\n            > span {\n                opacity: 0;\n                transition: opacity 200ms ease;\n            }\n        }\n    }\n\n    .nav-link {\n        .collapse-arrow {\n            transition: transform .3s ease-in-out, opacity .25s ease-in-out .1s;\n            transform: rotate(0);\n        }\n    }\n\n    > .children {\n        overflow: hidden;\n    }\n\n    &.open {\n\n        > .nav-link {\n\n            .collapse-arrow {\n                transform: rotate(90deg);\n            }\n        }\n\n        > .children {\n        }\n\n    }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
