import { NgModule, APP_INITIALIZER } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslatePipe } from '../pipes/translate-pipe';
import { TranslateService } from '@ngx-translate/core';
import { AppPipesModule } from '../pipes/pipes.module';
import { JabilSelectComponent } from './jabil-select/jabil-select.component';
import { JabilInputComponent } from './jabil-input/jabil-input.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { JabilCheckBoxComponent } from './jabil-check-box/jabil-check-box.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JabilTableComponent } from './jabil-table/jabil-table.component';
import { NgPrimeModule } from '../modules/ngprime.module';
import { MaterialModule } from '../modules/material.module';
import { JabilButtonComponent } from './jabil-button/jabil-button.component';
import { JabilTextAreaComponent } from './jabil-text-area/jabil-text-area.component';


export function setupTranslateFactory(
  service: TranslateService): Function {
  return () => service.use('en');
}

@NgModule({
  declarations: [
    JabilSelectComponent,
    JabilInputComponent,
    JabilCheckBoxComponent,
    JabilTableComponent,
    JabilButtonComponent,
    JabilTextAreaComponent
  ],
  imports: [
    CommonModule,
    AppPipesModule,
    NgSelectModule,
    FormsModule,
    NgPrimeModule,
    MaterialModule,
    ReactiveFormsModule
  ],
  exports: [
    JabilSelectComponent,
    JabilInputComponent,
    JabilCheckBoxComponent,
    JabilTableComponent,
    JabilButtonComponent,
    JabilTextAreaComponent,
  ],
  providers: [
    TranslateService,
    {
      provide: APP_INITIALIZER,
      useFactory: setupTranslateFactory,
      deps: [TranslateService],
      multi: true
    }
  ],
})
export class ControlModule { }
