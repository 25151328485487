import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CognitoGuard } from './core/cognito-guard/cognito-guard';
import { Error404Component } from './main/errorsPages/404/error-404.component';

const appTitle = 'Jabil PTU'

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./main/content/sections/sections.module').then(mod => mod.SectionsModule),
    canActivate: [CognitoGuard],
    title: appTitle
  },
  {
    path: '**',
    component: Error404Component
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
