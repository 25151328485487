import {
  Component,
  Input,
  OnInit,
  Output,
  ViewChild,
  EventEmitter,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Table } from 'primeng/table';
import { ColumnTypeEnum } from '../../enumerations/JabilEnumeration';
import { IColumn } from '../../interfaces/IColumn';
import { IPagedResult } from '../../interfaces/IPagedResult';
import { TransactionResult } from '../../services/interfaces/transaction-result';
import { NotificationService } from '../../services/notification/notification.service';
import { TranslateApplicationService } from '../../translate/translate-application-service';
import {
  IActionCustomData,
  ICustomAction,
} from '../../interfaces/ICustomAction';
import { IDefaultFilter } from '../../interfaces/IDefaultFilter';
import { JabilTableService } from '../../../main/content/sections/services/jabil-table-service';
import { Paginator } from 'primeng/paginator';

@Component({
  selector: 'jabil-table',
  templateUrl: './jabil-table.component.html',
  styleUrls: ['./jabil-table.component.scss'],
})
export class JabilTableComponent implements OnInit {
  @Input() protected columns: Array<IColumn>;
  @Input() protected station: string;
  @Input() protected title: string;
  @Input() protected showFilterByColumn = false;
  @Input() protected hideButtonAdd: boolean = false;
  @Input() protected hideButtonEdit: boolean = false;
  @Input() protected hideButtonExport: boolean = false;
  @Input() protected hideButtonRefresh: boolean = false;
  @Input() protected controller: string = '';
  @Input() protected endPoint: string = '';
  @Input() protected customAction: Array<ICustomAction>;
  @Input() protected defaultFilter: boolean = false;
  @Input() protected service: string = '';
  @Input() protected filterByEnter: boolean = false;

  @Output() protected buttonAdd = new EventEmitter<any>();
  @Output() protected buttonEdit = new EventEmitter<any>();
  @Output() protected customActionEvent = new EventEmitter<any>();
  @ViewChild('jabilTable') protected table: Table;
  @ViewChild('jabilPaginator') protected paginator: Paginator;

  protected selectedData: any = null;
  protected pagedResult: IPagedResult;
  protected data: any[];
  protected pageNumber: number = 0;
  protected pageCount: number = 0;
  protected pageSize: number = 10;
  protected loading: boolean = false;
  protected rowCount: number = 0;
  protected myPaginationString: string = '';
  protected predicate: string = 'p => true';
  protected sort: string = 'order => order.';
  protected sortDirection: string = 'Ascending';
  protected clickCheckBox: boolean = false;
  protected clickDatePicker: boolean = false;
  protected indeterminate: boolean = true;
  protected formFilter: FormGroup;
  protected orderTypeList: Array<any> = [
    { name: 'ASCENDING', value: 'Ascending' },
    { name: 'DESCENDING', value: 'Descending' },
  ];
  protected propertyReferences: string = '';
  protected defaultFilterList: Array<IDefaultFilter> = [];

  constructor(
    private notificationService: NotificationService,
    private translateService: TranslateApplicationService,
    private jabilTableService: JabilTableService,
    private formBuilder: FormBuilder,
  ) {
    this.formFilter = this.formBuilder.group({
      order: [''],
      orderType: [''],
    });
  }

  ngOnInit(): void {
    this.setInitial();
  }

  protected setInitial(): void {
    let principalColumn: string = this.columns.find(
      (p) => p.principal,
    ).name;
    this.sort += principalColumn;

    this.formFilter.controls.order.setValue(principalColumn);
    this.formFilter.controls.orderType.setValue(this.sortDirection);

    this.columns.forEach((column) => {
      if (column.reference) {
        if (this.propertyReferences.length == 0)
          this.propertyReferences +=
            'prop => prop.' + column.reference;
        else
          this.propertyReferences +=
            ';prop => prop.' + column.reference;
      }
    });

    if (!this.defaultFilter) this.getData();
  }

  setDefaultFilters(defaultFilters: IDefaultFilter[]): void {
    this.predicate = '';
    this.defaultFilterList = defaultFilters;

    this.setDefaultPredicate();
  }

  setDefaultPredicate(): void {
    this.defaultFilterList.forEach((element) => {
      if (this.predicate.length == 0) {
        switch (element.type) {
          case ColumnTypeEnum.Number:
            this.predicate =
              'p => p.' +
              element.field +
              ' == ' +
              Number(element.value);
            break;
          case ColumnTypeEnum.String:
            this.predicate =
              'p => p.' +
              element.field +
              '.%3FToLower().Contains("' + element.value.toLowerCase() + '") ?? false';
            break;
          case ColumnTypeEnum.Date:
            this.predicate =
              'p => p.' +
              element.field +
              '.Date == System.Convert.ToDateTime("' +
              element.value +
              '").Date';
            break;
          case ColumnTypeEnum.Boolean:
            this.predicate =
              'p=> p.' + element.field + ' == ' + element.value;
            break;
          case ColumnTypeEnum.Time:
            this.predicate = `p => p.${element.field
              }.CompareTo(System.TimeSpan.Parse("${element.value
              }")) == ${0}`;
            break;
        }
      } else {
        switch (element.type) {
          case ColumnTypeEnum.Number:
            this.predicate +=
              ' && p.' + element.field + ' == ' + element.value;
            break;
          case ColumnTypeEnum.String:
            this.predicate +=
              ' && p.' +
              element.field +
              '%3F.ToLower().Contains("' + element.value.toLowerCase() + '") ?? false';
            break;
          case ColumnTypeEnum.Date:
            this.predicate +=
              ' && p.' +
              element.field +
              '.Date == System.Convert.ToDateTime("' +
              element.value +
              '").Date';
            break;
          case ColumnTypeEnum.Boolean:
            this.predicate +=
              ' && p.' + element.field + ' == ' + element.value;
            break;
          case ColumnTypeEnum.Time:
            this.predicate += ` && p.${element.field
              }.CompareTo(System.TimeSpan.Parse("${element.value
              }")) == ${0}`;
            break;
        }
      }
    });

    this.getData();
  }

  ngOnChanges(): void {
    if (this.pagedResult)
      this.myPaginationString =
        this.translateService.data.TABLE.SHOWING +
        this.pagedResult.firstRowOnPage +
        this.translateService.data.TABLE.TO +
        this.pagedResult.lastRowOnPage +
        this.translateService.data.TABLE.OF +
        this.pagedResult.rowCount +
        this.translateService.data.TABLE.RECORDS;
  }

  protected add(): void {
    this.buttonAdd.emit();
  }

  protected edit(): void {
    if (this.selectedData == null || this.selectedData == undefined) {
      this.notificationService.error(this.translateService.data.TABLE.SELECT_ROW);

      return;
    }

    this.buttonEdit.emit(this.selectedData);
  }

  getData(): void {
    this.loading = true;
    this.jabilTableService
      .getByPagination(
        this.service,
        this.controller,
        this.endPoint,
        this.pageNumber + 1,
        this.pageSize,
        encodeURIComponent(this.predicate),
        this.sort,
        this.sortDirection,
        this.propertyReferences,
      )
      .subscribe(
        (result: TransactionResult<IPagedResult>) => {
          this.loading = false;
          if (result.success) {
            this.table.sortOrder = 0;
            this.table.sortField = '';
            this.table.reset();
            this.pagedResult = result.data;
            this.data = this.pagedResult.results;
            this.rowCount = this.pagedResult.rowCount;
            this.pageCount = this.pagedResult.pageCount;

            this.myPaginationString =
              this.translateService.data.TABLE.SHOWING +
              this.pagedResult.firstRowOnPage +
              this.translateService.data.TABLE.TO +
              this.pagedResult.lastRowOnPage +
              this.translateService.data.TABLE.OF +
              this.pagedResult.rowCount +
              this.translateService.data.TABLE.RECORDS;
          }
        },
        (error: any) => {
          this.loading = false;
        },
      );
  }

  protected paginate(event: any): void {
    this.pageNumber = event.page;
    this.pageSize = event.rows;

    this.getData();
  }

  protected filterByColumn(): void {
    let filters: NodeListOf<HTMLInputElement> = <
      NodeListOf<HTMLInputElement>
      >document.getElementsByName('filterByColumn');

    this.predicate = '';

    filters.forEach((element) => {
      if (element.type == 'text') {
        if (element.value.length > 0) {
          let dataType: ColumnTypeEnum = <ColumnTypeEnum>(
            (<Number>(<unknown>element.getAttribute('data-type')))
          );

          if (this.predicate.length == 0) {
            switch (Number(dataType)) {
              case ColumnTypeEnum.Number:
                this.predicate =
                  'p => p.' +
                  element.title +
                  ' == ' +
                  Number(element.value);
                break;
              case ColumnTypeEnum.String:
                this.predicate =
                  'p => p.' +
                  element.title +
                  '?.ToLower().Contains("' + element.value.toLowerCase() + '") ?? false'
                break;
            }
          } else {
            switch (Number(dataType)) {
              case ColumnTypeEnum.Number:
                this.predicate +=
                  ' && p.' +
                  element.title +
                  ' == ' +
                  element.value;
                break;
              case ColumnTypeEnum.String:
                this.predicate +=
                  ' && p.' +
                  element.title +
                  '.ToLower().Contains("' + element.value.toLowerCase() + '") ?? false';
                break;
            }
          }
        }
      }

      if (element.type == 'date') {
        if (element.value.length > 0) {
          if (this.predicate.length == 0)
            this.predicate =
              'p => p.' +
              element.title +
              '.Date == System.Convert.ToDateTime("' +
              element.value +
              '").Date';
          else
            this.predicate +=
              ' && p.' +
              element.title +
              '.Date == System.Convert.ToDateTime("' +
              element.value +
              '").Date';
        }
      }

      if (this.clickCheckBox) {
        if (element.type == 'checkbox') {
          if (this.predicate.length == 0)
            this.predicate =
              'p=> p.' +
              element.id.split('-')[0] +
              ' == ' +
              element.checked;
          else
            this.predicate +=
              ' && p.' +
              element.id.split('-')[0] +
              ' == ' +
              element.checked;
        }
      }

      if (element.type == 'time') {
        if (element.value.length > 0) {
          if (this.predicate.length == 0)
            this.predicate = `p => p.${element.title
              }.CompareTo(System.TimeSpan.Parse("${element.value
              }")) == ${0}`;
          else
            this.predicate += ` && p.${element.title
              }.CompareTo(System.TimeSpan.Parse("${element.value
              }")) == ${0}`;
        }
      }
    });

    if (this.predicate.length == 0) {
      this.predicate = 'p => true';

      this.clickCheckBox = false;
      this.clickDatePicker = false;
    }

    if (!this.defaultFilter) this.getData();
    else this.setDefaultPredicate();
  }

  protected filterData(): void {
    this.sort = 'order => order.' + this.formFilter.controls.order.value;
    this.sortDirection = this.formFilter.controls.orderType.value;

    this.getData();
  }

  protected export(): void {
    if (this.pagedResult) {
      this.loading = true;
      this.jabilTableService
        .getByPagination(
          this.service,
          this.controller,
          this.endPoint,
          1,
          this.pagedResult.rowCount,
          this.predicate,
          this.sort,
          this.sortDirection,
          this.propertyReferences,
        )
        .subscribe(
          (result: TransactionResult<IPagedResult>) => {
            this.loading = false;
            if (result.success) {
              this.exportExcel(result.data);
            }
          },
          (error: any) => {
            this.loading = false;
          },
        );
    }
  }

  protected exportExcel(data: IPagedResult): void {
    import('xlsx').then((xlsx) => {
      let headers: Array<string> = [];
      let properties: Array<string> = [];

      this.columns.forEach((column) => {
        headers.push(
          this.translateService.translateKey(column.translate),
        );
        properties.push(column.caption);
      });

      var heading = [headers];

      var records = data.results.map((result) => {
        var object = {};

        this.columns.forEach((p) => {
          object[p.caption] = result[p.caption];
        });

        return object;
      });

      const ws = xlsx.utils.book_new();
      const wb = xlsx.utils.json_to_sheet([]);

      xlsx.utils.sheet_add_aoa(wb, heading);
      xlsx.utils.sheet_add_json(wb, records, {
        origin: 'A2',
        skipHeader: true,
        header: properties,
      });
      xlsx.utils.book_append_sheet(ws, wb, this.title);
      xlsx.writeFile(ws, this.title + '.xlsx');
    });
  }

  protected clickCustomAction(data: any, actionName: string): void {
    let actionData: IActionCustomData = { data: data, name: actionName };
    this.customActionEvent.emit(actionData);
  }

  protected refresh(): void {
    this.paginator.changePage(0);
    this.sort = 'order => order.';
    this.sortDirection = 'Ascending';
    this.predicate = 'p => true';
    this.pageNumber = 0;
    this.pageCount = 0;
    this.pageSize = 10;
    this.table.reset();
    this.clickCheckBox = false;
    this.clickDatePicker = false;
    this.indeterminate = true;
    this.rowCount = 0;
    this.propertyReferences = "";


    let filters: NodeListOf<HTMLInputElement> = <
      NodeListOf<HTMLInputElement>
      >document.getElementsByName('filterByColumn');
    filters.forEach((element) => {
      if (
        element.type == 'text' ||
        element.type == 'date' ||
        element.type == 'time'
      ) {
        element.value = '';
      }
    });

    if (this.defaultFilter) this.setDefaultPredicate();
    else this.setInitial();
  }
}
