import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
	selector: 'jabil-button',
	templateUrl: './jabil-button.component.html',
	styleUrls: ['./jabil-button.component.scss'],
})
export class JabilButtonComponent implements OnInit {
	@Output() eventClick = new EventEmitter<any>();
	@Input() caption: string = '';
	@Input() className: string = '';
	@Input() disabled: boolean = false;
	@Input() formGroup: FormGroup;
	@Input() loading: boolean = false;
	@Input() tooltip: string = '';
	@Input() tooltipPosition: string = '';
	@Input() hidden: boolean = false;
	@Input() hasIcon: boolean = false;
	@Input() classNameIcon: string = '';
	@Input() iconPos: string = 'left';

	constructor() {}

	ngOnInit(): void {}

	action() {
		if (this.formGroup) {
			Object.keys(this.formGroup.controls).forEach((key) => {
				const ctrl = this.formGroup.get(key);
				if (ctrl.invalid) {
					var control = <HTMLElement>document.getElementById(key);
					if (control) control.classList.add('customError');
					ctrl.markAsTouched({ onlySelf: true });
					ctrl.updateValueAndValidity();
				}
			});
		}

		this.eventClick.emit();
	}
}
