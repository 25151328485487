import { NgModule } from '@angular/core';

import { KeysPipe } from './keys.pipe';
import { TranslatePipe } from './translate-pipe';
import { SafePipe } from './safe.pipe';

@NgModule({
    declarations: [
    KeysPipe,
    TranslatePipe,
    SafePipe
    ],    
    exports: [
      KeysPipe,
      TranslatePipe,
      SafePipe
    ]
})

export class AppPipesModule { }
