import { Injectable } from '@angular/core';
import { RestCallService } from '../../../../core/services/rest-call/rest-call.service';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from 'src/app/core/services/notification/notification.service';
import { TranslateApplicationService } from '../../../../core/translate/translate-application-service';
import { IPagedResult } from '../../../../core/interfaces/IPagedResult';

@Injectable({
  providedIn: 'root'
})
export class JabilTableService extends RestCallService {

  constructor(
    public httpClient: HttpClient,
    public toastr: ToastrService,
    public notificationService: NotificationService,
    public translate: TranslateApplicationService
  ) {
    super(httpClient, 'JabilTable', toastr, notificationService, translate);
  }

  public getByPagination(service: string, controller: string, endPoint: string, pageNumber: number, pageSize: number, predicate: string, sort: string, sortDirection: string, reference?: string) {
    this.setUrlKey(service);
    return this.get<IPagedResult>(`${controller}/${endPoint}/${pageNumber}/${pageSize}/${predicate}/${sort}/${sortDirection}/${reference}`);
  }

 
}
