import { Injectable } from '@angular/core';
import { Alert } from '../../../main/quick-panel/alert.model';
import { BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '../translate-service';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  public alerts: BehaviorSubject<Alert[]> = new BehaviorSubject([]);
  constructor(private toastr: ToastrService, private translateService: TranslateService) {

  }
  public addAlert(alert: Alert) {
    this.addingAlert(alert);

    alert.detail = this.translateService.translate(alert.detail);

    if (alert.severity.toUpperCase().trim() == "SUCCESS") {
      this.toastr.success(alert.summary, alert.detail);
    }
    if (alert.severity.toUpperCase().trim() == "ERROR") {
      this.toastr.error(alert.summary, alert.detail);
    }
    if (alert.severity.toUpperCase().trim() == "WARN") {
      this.toastr.warning(alert.summary, alert.detail);
    }
    if (alert.severity.toUpperCase().trim() == "INFO") {
      this.toastr.info(alert.summary, alert.detail);
    }
  }

  public success(message: string, addTracker = true) {
    let alert: Alert = { detail: message, severity: "success", summary: "Success", addTracker: addTracker };
    this.addAlert(alert);
  }

  public error(message: string, addTracker = true) {
    let alert: Alert = { detail: message, severity: "error", summary: "error", addTracker: addTracker };
    this.addAlert(alert);
  }

  public waring(message: string, addTracker = true) {
    let alert: Alert = { detail: message, severity: "warn", summary: "Warn", addTracker: addTracker };
    this.addAlert(alert);
  }

  public info(message: string, addTracker = true) {
    let alert: Alert = { detail: message, severity: "info", summary: "Info", addTracker: addTracker };
    this.addAlert(alert);
  }

  private addingAlert(alert: Alert): void {
    if (alert.addTracker) {
      let getAlerts: Array<Alert> = this.alerts.value;
      getAlerts = [...getAlerts, alert];

      this.alerts.next(getAlerts);
    }
  }
}
