export class AppNavigationModel {
  public model: any[];

  constructor() {
    this.model = [
      {
        'id': 'applications',
        'title': 'Applications',
        'translate': 'NAV.APPLICATIONS',
        'type': 'group',
        'icon': 'apps',
        'children': [
          {
            id: 'ptus-management',
            title: 'PTUs management',
            translate: 'NAVBAR.ptus_management',
            type: 'item',
            url: '/ptus-management',
            icon: 'home'
          },
        ]
      }
    ];
  }
}

