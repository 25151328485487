// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main {
  width: 100%;
  height: 100vh;
  background-color: #f4ffdf;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main .content {
  text-align: center;
  width: 50%;
  margin: auto;
}
.main .content img {
  width: 51%;
}
.main .content .code {
  font-size: 60px;
  margin-bottom: 10px;
}
.main .content .text {
  font-size: 25px;
  margin-top: 10px;
}`, "",{"version":3,"sources":["webpack://./src/app/main/errorsPages/404/error-404.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,aAAA;EACA,yBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AACF;AACE;EACI,kBAAA;EACA,UAAA;EACA,YAAA;AACN;AACM;EACI,UAAA;AACV;AAEM;EACQ,eAAA;EACA,mBAAA;AAAd;AAGM;EACI,eAAA;EACA,gBAAA;AADV","sourcesContent":[".main {\n  width: 100%;\n  height: 100vh;\n  background-color: #f4ffdf;\n  display: flex;\n  justify-content: center;\n  align-items: center;\n\n  .content {\n      text-align: center;\n      width: 50%;\n      margin: auto;\n\n      img {\n          width: 51%;\n      }\n\n      .code {\n              font-size: 60px;\n              margin-bottom: 10px;\n      }\n  \n      .text {\n          font-size: 25px;\n          margin-top: 10px;\n      }\n             \n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
