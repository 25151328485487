import { Pipe, PipeTransform } from '@angular/core';
import { TranslateApplicationService } from '../translate/translate-application-service';

@Pipe({
  name: 'translate',
  pure: false
})
export class TranslatePipe implements PipeTransform {
  constructor(private translate: TranslateApplicationService) {
    ///this.initialize();
  }

  async initialize() {
    //await this.translate.use({ flag : 'es' });
  }

  transform(key: any, parameters: object): any {
    if (key.split(".").length == 1)
      return this.translate.data[key] || key;
    else {
      let arrayKey: any;
      arrayKey = key.split(".");
      let valueFind: any = "";

      let index: number = 0;

      for (let item of arrayKey) {
        if (index == 0)
          valueFind = this.translate.data[item];
        else {
          valueFind = valueFind == undefined ? key : valueFind[item];
        }

        index++;
      }

      if (valueFind && parameters) {
        Object.keys(parameters).forEach(key => {
          if (valueFind.includes("{" + key + "}"))
            valueFind = valueFind.replace("{" + key + "}", parameters[key]);
        });
      }

      return valueFind;
    }
  }
}