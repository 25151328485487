import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { TransactionResult } from '../interfaces/transaction-result';
import { Observable, merge, Subject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { NotificationService } from '../notification/notification.service';
import { tap } from 'rxjs/operators';
import { TranslateApplicationService } from '../../translate/translate-application-service';
import { HttpParams } from '@angular/common/http';
import { convertToParamMap } from '@angular/router';
import { IBaseParams } from 'src/app/main/content/sections/interfaces/IBaseParams';

export var self: RestCallService = null;
/*
 *
 *abstract class to request resources from resources servers
 *
 * */
export abstract class RestCallService {
  private urlBase: string;

  constructor(public http: HttpClient
    , urlKey: string
    , public toastr: ToastrService
    , public notificationService: NotificationService,
    public translate: TranslateApplicationService) {
    if (urlKey != "JabilTable") {
      if (!Boolean(urlKey)) {
        throw Error("property urlBase:string is not defined , consider to configure the app on quetzalcoatl maintenance");
      }
      this.urlBase = (<any>window).configuration.enviroment[urlKey];
      if (!Boolean(this.urlBase)) {
        throw Error("property urlBase:string is not defined inside enviroment variables on quetzalcoatl maintenance");
      }
    }
    self = this;
  }

  setUrlKey(urlKey: string): void {
    self.urlBase = (<any>window).configuration.enviroment[urlKey];
    if (!Boolean(self.urlBase)) {
      throw Error("property urlBase:string is not defined inside enviroment variables on quetzalcoatl maintenance");
    }
  }

  private getFullUrl(url: string): string {
    return this.urlBase + url;
  }

  private success(data: TransactionResult<any>): void {
    if (data.success) {
      if (Boolean(data.message)) {
        self.toastr.info(data.translateParams ? self.translate.translateKeyWithParameters(data.message, data.translateParams) : `${self.translate.translateKey(data.message)}`);
      }
      if (Boolean(data.messages)) {
        for (let item of data.messages) {
          self.notificationService.info(data.translateParams ? self.translate.translateKeyWithParameters(item, data.translateParams) : self.translate.translateKey(item) != undefined ? `${self.translate.translateKey(item)}` : item);
        }
      }
    }
    if (!data.success) {
      if (self.translate != null) {
        if (data.message != null) {
          self.notificationService.error(data.translateParams ? self.translate.translateKeyWithParameters(data.message, data.translateParams) : `${self.translate.translateKey(data.message)}`);
        }
        if (Boolean(data.messages)) {
          for (let item of data.messages) {
            self.notificationService.error(`${self.translate.translateKey(item)}`);
          }
        }
      }
    }
  }

  private error(error: HttpErrorResponse): void {
    self.notificationService.addAlert({
      severity: 'error',
      detail: error.message,
      summary: "Error",
      addTracker: true
    });
    if (error.status === 401) {
      self.toastr.warning(error.message);
    }
    if (error.status === 500) {
      self.toastr.error(error.message);
    }
    if (error.status === 404) {
      self.toastr.info(error.message);
    }
    if (error.status === 0) {
      self.toastr.info(error.message);
    }
    if (error.status === 405) {
      self.toastr.info(error.message);
    }
  }


  /*
   *
   *http get 
   * 
   */
   public get<TResult>(url: string, data?: any, headers?: HttpHeaders | any): Observable<TransactionResult<TResult>> {
    if (!Boolean(headers)) {
      headers = {};
    }

    return this.http.get<TransactionResult<TResult>>(this.getFullUrl(url), {
      headers: Object.assign(headers, {
        'urlBase': this.urlBase
      }),
      params: data
    }).pipe(tap(this.success, this.error));
  }

  /*
   *
   *http post 
   * 
   */
  public post<TResult>(url: string, data?: any, headers?: HttpHeaders | any): Observable<TransactionResult<TResult>> {
    if (!Boolean(headers)) {
      headers = {};
    }

    //Adds the fk_site and accessToken variable to any request
    if(!data)
      data = {};

    //Adds the access token and fk site for all non public requests
    data['accessToken'] = window.localStorage.getItem('accessToken');
    data['fk_site'] = window.localStorage.getItem('fk_site') ?? 0;

    return this.http.post<TransactionResult<TResult>>(this.getFullUrl(url), data, {
      headers: {
        'urlBase': this.urlBase
      }
    }).pipe(tap(this.success, this.error));
  }

  public postWithFile<TResult>(url: string,  file: File, data: any = {}, headers?: HttpHeaders | any): Observable<TransactionResult<TResult>> {
    //Adds the fk_site and accessToken variable to any request
    if(!data)
      data = {};
    
     //Adds the access token and fk site for all non public requests
     data['accessToken'] = window.localStorage.getItem('accessToken');
     data['fk_site'] = window.localStorage.getItem('fk_site') ?? 0;
     
    const formData = new FormData();
    formData.append('file', file);
    
    Object.keys(data).forEach(key => {
      formData.append(key, data[key]);
    });


    if (!Boolean(headers)) {
      headers = {};
    }

    return this.http.post<TransactionResult<TResult>>(
      this.getFullUrl(url),
      formData,
      { headers }
    ).pipe(
      tap(this.success, this.error)
    );
  }

  /*
   *
   *http put 
   * 
   */
  public put<TResult>(url: string, data?: any, headers?: HttpHeaders | any): Observable<TransactionResult<TResult>> {
    if (!Boolean(headers)) {
      headers = {};
    }
    return this.http.put<TransactionResult<TResult>>(this.getFullUrl(url), data, {
      headers: {
        'urlBase': this.urlBase
      }
    }).pipe(tap(this.success, this.error));
  }


  /*
   *
   *http delete
   * 
   */
  public delete<TResult>(url: string, params?: any, headers?: HttpHeaders | any): Observable<TransactionResult<TResult>> {
    if (!Boolean(headers)) {
      headers = {};
    }
    return this.http.delete<TransactionResult<TResult>>(this.getFullUrl(url), {
      headers: {
        'urlBase': this.urlBase
      }, params: params
    }).pipe(tap(this.success, this.error));
  }
}
