import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ReactiveFormsModule } from '@angular/forms'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './core/modules/shared.module';
import { MainModule } from './main/main.module';
import { AppSplashScreenService } from './core/services/splash-screen.service';
import { AppConfigService } from './core/services/config.service';
import { NavigationService } from './core/components/navigation/navigation.service';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { Error404Component } from './main/errorsPages/404/error-404.component';
import { InterceptorService } from './core/services/rest-call/interceptor.service';
import { ToastrService, ToastrModule } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { TranslateApplicationService } from './core/translate/translate-application-service';
import { TranslateService } from './core/services/translate-service';
import { ApplicationInfoService } from './core/translate/application-info.service';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { PrimeNGConfig } from 'primeng/api';
import { CognitoService } from './core/services/cognito/cognito.service';
import { CognitoGuard } from './core/cognito-guard/cognito-guard';

import { StoreModule } from '@ngrx/store';
import { appReducer } from './state/state.reducer';

function getApplicationInfo(application: ApplicationInfoService) {
  return () => application.loadLanguages();
}

const initializeAppFactory = (primeConfig: PrimeNGConfig  ) => () => {
  primeConfig.ripple = true;
};

@NgModule({
  declarations: [
    AppComponent,
    Error404Component
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    SharedModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-top-right'
    }),
    TranslateModule.forRoot(),
    MainModule,
    StoreModule.forRoot({ app: appReducer })
  ],
  providers: [
    AppSplashScreenService,
    AppConfigService,
    NavigationService,
    ToastrService,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true
    },
    TranslateApplicationService,
    TranslateService,
    {
      provide: APP_INITIALIZER,
      useFactory: getApplicationInfo,
      deps: [ApplicationInfoService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializeAppFactory,
      deps: [PrimeNGConfig],
      multi: true
    },
    CognitoService,
    CognitoGuard
  ],
  bootstrap: [AppComponent],
  exports: [ ]
})
export class AppModule { }
