import { Output } from '@angular/core';
import {
	Component,
	OnInit,
	Input,
	forwardRef,
	Injector,
	ViewChild,
	ElementRef,
	EventEmitter,
} from '@angular/core';
import {
	ControlValueAccessor,
	FormControl,
	NgControl,
	NG_VALUE_ACCESSOR,
} from '@angular/forms';

@Component({
	selector: 'jabil-input',
	templateUrl: './jabil-input.component.html',
	styleUrls: ['./jabil-input.component.scss'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => JabilInputComponent),
			multi: true,
		},
	],
})
export class JabilInputComponent implements OnInit, ControlValueAccessor {
	@Input() caption: string;
	@Input() nameControl: string;
	@Input() placeholder: string;
	@Input() type: string = 'text';
	@Input() isReadOnly: boolean = false;
	@Input() style: string = '';
	@Input() isDisabled: boolean;
	@Input() class: string;
	@Input() tooltip_message: string = ""; 
	@Input() tooltip_position: string = "top";
	value: any;
	fm = new FormControl();
	ngControl: NgControl;
	@ViewChild('input') input: ElementRef;
	@Output() focusEvent = new EventEmitter<any>();
	@Output() changeValue: EventEmitter<any> = new EventEmitter();
	@Output() enterPressed: EventEmitter<void> = new EventEmitter<void>();

	constructor(private injector: Injector) {}

	ngOnInit(): void {
		this.ngControl = this.injector.get(NgControl);
		this.fm.valueChanges.subscribe((v) => {
			this.onChange(v);
		});
	}

	onChange = (_: any) => {};
	onTouch = () => {};

	handleChange(value: any) {
		this.value = value.replace(/\s+/gi, ' ').trim();
	}

	onInput(value: any) {
		this.value = value.replace(/\s+/gi, ' ').trim();
		this.onTouch();
		this.onChange(this.value);
		this.changeValue.emit(this.value);

		if (this.value) {
			let element = this.input.nativeElement as HTMLElement;
			element.classList.remove('customError');
		}
	}

	onEnterKeyPressed() {
		this.enterPressed.emit();
	  }

	writeValue(value: any): void {
		if (value) {
			this.value = value || '';
		} else {
			this.value = '';
		}
	}

	onFocus(event: any): void {
		this.focusEvent.emit(event);
	}

	registerOnChange(fn: any): void {
		this.onChange = fn;
	}
	registerOnTouched(fn: any): void {
		this.onTouch = fn;
	}
	setDisabledState(isDisabled: boolean): void {
		this.isDisabled = isDisabled;
	}
}
