import { Injectable } from '@angular/core';
import {
	HttpRequest,
	HttpHandler,
	HttpEvent,
	HttpInterceptor,
	HttpHeaders,
	HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
import { CognitoService } from '../cognito/cognito.service';
import { Router } from '@angular/router';

@Injectable({
	providedIn: 'root',
})
export class InterceptorService implements HttpInterceptor {
  constructor(
	private cognitoService: CognitoService,
	private router: Router
) {
}

	publicRoutes: Array<string> = ['Users/AuthUser', 'assets/i18n/', 'Ptus/SearchPTUs', 'Ptus/UpdateEmployeeData', 'Environments/GetActiveEnvironment',
	'Environments/GetS3FilesApiUrls', 'Ptus/CreateFiscalStatusConstance', 'Ptus/GetSitesContactEmails',
	'aws.com/Stage/layout_files', 'aws.com/Stage/with_content_type', 's3.amazonaws.com'];

	intercept(
		req: HttpRequest<any>,
		next: HttpHandler,
	): Observable<HttpEvent<any>>
	{
		let headersNew = new HttpHeaders();

		req.headers.keys().forEach((key) => {
			if (key !== 'urlBase') {
				headersNew = headersNew.append(key, req.headers.get(key));
			}
		});

		//For public routes authentication is not required
		if(this.publicRoutes.some(endpoint => req.url.includes(endpoint))){
			return next.handle(req);
		}

		//At this point, is not a public function
		//If the user is not authenticated, is redirected to the login
		if(!this.cognitoService.IsAuhtenticated()){
			this.router.navigateByUrl('/');
			throw new Error('ERRORS.user_not_logged_in');
		}

		if(!this.cognitoService.CheckValidSession()){
			this.router.navigateByUrl('/');
			throw new Error('ERRORS.session_timeout');
		}

		this.cognitoService.RefreshSessionLife();

    return this.cognitoService.getAccessToken().pipe(
      mergeMap((token: string) => {
        const authorizedRequest = req.clone({
          headers: headersNew.append('Authorization', `Bearer ${token}`),
        });
        return next.handle(authorizedRequest);
      }));
	}
}
