import { createSelector } from '@ngrx/store';

const selectAppState = (state: any) => state.app;

export const selectAccessToken = createSelector(
    selectAppState,
    (appState) => appState.accessToken,
);

export const selectEmail = createSelector(
    selectAppState,
    (appState) => appState.email,
);