import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { NavigationEnd, NavigationStart, Router, RouteReuseStrategy } from '@angular/router';
import { AppConfigService } from '../../core/services/config.service';
import { NotificationService } from '../../core/services/notification/notification.service';
import { ApplicationInfoService } from '../../core/translate/application-info.service';
import { TransactionResult } from '../../core/services/interfaces/transaction-result';
import { CognitoService } from '../../core/services/cognito/cognito.service';
import { SiteService } from '../content/sections/services/site-service';
import { ISite } from '../content/sections/interfaces/ISite';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateApplicationService } from 'src/app/core/translate/translate-application-service';
import { NavigationService } from 'src/app/core/components/navigation/navigation.service';
import { AppNavigationModel } from 'src/app/navigation/navigation.model';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { selectEmail } from 'src/app/state/state.selector';
import { ReloadService } from '../content/sections/services/reload-service';

declare const jQuery:any;

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss']
})

export class AppToolbarComponent {
  emailObserver$: Observable<any>;

  userStatusOptions: any[];
  languages: Array<any> = [];
  selectedLanguage: any;
  showLoadingBar: boolean;
  horizontalNav: boolean;
  username: string;
  appName: string;
  appVersion: string;
  sites: any[];
  showSite: boolean;
  formGroup: FormGroup;
  listSites: Array<any> = [];
  currentSite: any;

  constructor(
    private router: Router,
    private appConfig: AppConfigService,
    private translate: TranslateApplicationService,
    private applications: ApplicationInfoService,
    private cognitoService: CognitoService,
    //private logService: LogService,
    private notification: NotificationService,
    private siteService: SiteService,
    private formBuilder: FormBuilder,
    private appNavigationService: NavigationService,
    private store: Store,
    private reloadService: ReloadService
  ) {
    this.username = '';

    //When the username changes, it does in the toolbar too
    this.emailObserver$ = this.store.pipe(select(selectEmail));

    this.emailObserver$.subscribe(email => {
      this.username = email;
    })

    this.appName = window["applicationName"];
    this.appVersion = window["applicationVersion"];
    this.languages = applications.languages;
    this.formGroup = this.formBuilder.group({
			fk_site: [null, Validators.required],
		});

    if (this.languages.length == 0) {
      this.languages.push({
        id: 1,
        title: 'Español',
        flag: 'es',
        applicationId: 0
      });
      
      this.languages.push({
        id: 1,
        title: 'English',
        flag: 'en',
        applicationId: 0
      });
    }

    this.selectLanguage();

    router.events.subscribe(
      (event) => {
        if (event instanceof NavigationStart) {
          this.showLoadingBar = true;
        }
        if (event instanceof NavigationEnd) {
          this.showLoadingBar = false;
        }
      });

    this.appConfig.onSettingsChanged.subscribe((settings) => {
      this.horizontalNav = settings.layout.navigation === 'top';
    });

    this.GetSites();
  }

  GetSites() {
    this.sites = [];
		this.showSite = false;
    let lastSelectedSite = window.localStorage.getItem('fk_site');
    
    this.siteService.GetSitesByUsername().subscribe(
			(result: TransactionResult<ISite[]>) => {
        if (!result.success || result.data.length <= 0){
          this.router.navigateByUrl('/without-permission');
          return;
        }

        this.showSite = true;
        let sites = result.data;
        this.listSites = sites;
        
        //By default select the first site in list
        let toSelectSite = this.listSites[0].pk_site;

        //If previously a site was selected, set that as the selected site
        if(lastSelectedSite){
          let lastSelectedSiteInt = parseInt(lastSelectedSite);
          toSelectSite = sites.filter(x => x.pk_site == lastSelectedSiteInt)[0].pk_site;
        }
       
        localStorage.setItem('fk_site', toSelectSite.toString());
        this.formGroup.controls.fk_site.setValue(toSelectSite);

        this.appNavigationService.setNavigationModel(
           new AppNavigationModel(),
        );
      },
			(error: any) => {
			},
		);
	}

  ChangeSite(){
    if(!this.cognitoService.IsAuhtenticated())
      return;

    if(this.currentSite == this.formGroup.controls.fk_site.value)
      return;

    this.currentSite = this.formGroup.controls.fk_site.value;
    localStorage.setItem('fk_site', this.currentSite);
    this.reloadService.reload();
	}

  selectLanguage(): void {
    let indexLang = 0;
    if (this.languages != undefined) {
      if (indexLang < 0) {
        this.selectedLanguage = this.languages[0];
      } else {
        this.selectedLanguage = this.languages[indexLang];
      }

      this.setLanguage(this.selectedLanguage);
    }
  }

  setLanguage(lang) {
    // Set the selected language for toolbar
    this.selectedLanguage = lang;
    // selected language for translations
    this.translate.use(lang);
  }

  logOut(): void {
    this.cognitoService.logOut();
  }
}
