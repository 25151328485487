import { 
  Component, 
  ElementRef, 
  HostBinding, 
  Inject, 
  OnDestroy, 
  OnInit, 
  Renderer2, 
  ViewEncapsulation, 
  HostListener,
  ViewChild
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Platform } from '@angular/cdk/platform';
import { DOCUMENT } from '@angular/common';
import { AppConfigService } from '../core/services/config.service';
import { AppMatSidenavHelperService } from '../core/directives/app-mat-sidenav-helper/app-mat-sidenav-helper.service';
import { selectAccessToken } from '../state/state.selector';
import { Store, select } from '@ngrx/store';
import { CognitoService } from '../core/services/cognito/cognito.service';
import { EnvironmentService } from './content/sections/services/environment-service';
import { TransactionResult } from '../core/services/interfaces/transaction-result';
import { IEnvironment } from './content/sections/interfaces/IEnvironments';
import { NotificationService } from '../core/services/notification/notification.service';
import { ContentComponent } from './content/content.component';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MainComponent implements OnInit {
  @ViewChild(ContentComponent) appContent: ContentComponent;
  onSettingsChanged: Subscription;
  appSettings: any;
  accessToken$: Observable<any>;
  accessToken: null;
  loading: boolean = false;
  environment: IEnvironment = null;
  isTestingEnvironment: boolean = false;

  @HostBinding('attr.app-layout-mode') layoutMode;

  constructor(
    private appConfig: AppConfigService,
    private platform: Platform,
    private _elementRef: ElementRef,
    private _renderer: Renderer2,
    private appMatSidenavHelperService : AppMatSidenavHelperService,
    private store: Store,
    private cognitoService: CognitoService,
    private environmentService: EnvironmentService,
    private notificationService: NotificationService,
    @Inject(DOCUMENT) private document: any
  ) { 
    this.onSettingsChanged =
            this.appConfig.onSettingsChanged
                .subscribe(
                    (newSettings) => {
                        this.appSettings = newSettings;
                        this.layoutMode = this.appSettings.layout.mode;
                    }
                );

    if ( this.platform.ANDROID || this.platform.IOS )
    {
        this.document.body.className += ' is-mobile';
    }
  }

  ngOnInit() {
    this.accessToken$ = this.store.pipe(select(selectAccessToken));

    this.accessToken$.subscribe(accessToken => {
      this.accessToken = accessToken;
    })

    this.SetActiveEnvironment();
  }

  SetActiveEnvironment()
  {
    this.loading = true;
    this.environmentService.GetActiveEnvironment().subscribe(
      (result: TransactionResult<IEnvironment>) => {
        this.loading = false;

        if(!result.success)
          return;

        this.environment = result.data;
        this.isTestingEnvironment = this.environment.environment.toLowerCase() != 'production'
      },
      (error: any) => {
        this.loading = false;
      }
    )
  }

  ngOnDestroy()
  {
      this.onSettingsChanged.unsubscribe();
  }

  addClass(className: string)
  {
      this._renderer.addClass(this._elementRef.nativeElement, className);
  }

  removeClass(className: string)
  {
      this._renderer.removeClass(this._elementRef.nativeElement, className);
  }

  @HostListener('window:keydown.control.Enter', ['$event'])
  keyEvent(event: KeyboardEvent) {    
    this.appMatSidenavHelperService.getSidenav('config-app').toggle();
  }

}
