import { createReducer, on } from "@ngrx/store";
import { refreshPage, setGlobalVariable } from "./state.actions";

const initialState = {
    accessToken: null,
    email: '',
    appKey: 0
}

const appReducer = createReducer(
    initialState,
    on(setGlobalVariable, (state, { variable, newValue }) => {
      let newState = {...state};
      newState[variable] = newValue;
      return newState;
    }),
    on(refreshPage, (state, {}) => {
      let newState = {...state};
      newState.appKey++;
      return newState;
    })
  );

export { appReducer };